.palettes-color {
    flex-grow: 1;
    border: none;
    height: 2em;
}

.native-input {
    width: 100%;
    height: 4em;
}

.history-color {
    width: 3em;
    height: 2em;
    border: none;
}
