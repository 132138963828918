.palettes-color {
  height: 2em;
  border: none;
  flex-grow: 1;
}

.native-input {
  width: 100%;
  height: 4em;
}

.history-color {
  width: 3em;
  height: 2em;
  border: none;
}

/*# sourceMappingURL=index.7ad9eb74.css.map */
